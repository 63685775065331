import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import ArrowLongRightIcon from "@heroicons/react/24/solid/ArrowLongRightIcon";
import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import PaperAirplaneIcon from "@heroicons/react/24/outline/PaperAirplaneIcon";
import BuildingLibraryIcon from "@heroicons/react/24/outline/BuildingLibraryIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import ArrowUturnRightIcon from "@heroicons/react/24/outline/ArrowUturnRightIcon";
import ArrowPathRoundedSquareIcon from "@heroicons/react/24/outline/ArrowPathRoundedSquareIcon";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import DocumentArrowDownIcon from "@heroicons/react/24/outline/DocumentArrowDownIcon";
import ClipboardDocumentIcon from "@heroicons/react/24/outline/ClipboardDocumentIcon";
import dayjs from "dayjs";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import routes from "../../utils/routes";

const ICONS = {
  completed: {
    color: "success",
    icon: CheckCircleIcon,
    tooltip: "payout_completed",
  },
  pending: {
    color: "warning",
    icon: ExclamationTriangleIcon,
    tooltip: "payout_pending",
  },
  failed: { color: "error", icon: XMarkIcon, tooltip: "payout_failed" },
  canceled: { color: "disabled", icon: XMarkIcon, tooltip: "payout_canceled" },
  rescheduled: {
    color: "success",
    icon: ArrowUturnRightIcon,
    tooltip: "payout_rescheduled",
  },
};

export default function ({
  item,
  fx_rates,
  currency,
  selected,
  onClick,
  ...props
}) {
  const rescheduled = item.data?.rescheduled ? "rescheduled" : null;
  const Icon = ICONS[rescheduled || item.status]?.icon;
  const console_out = props.console;

  return (
    <Grid
      container
      spacing={0}
      onClick={onClick}
      sx={{
        p: 2,
        alignItems: "center",
        borderRadius: 0,
        cursor: onClick ? "pointer" : "inherit",
        bgcolor: selected ? "rgb(255,245,199,0.75)" : "inherit",
        "&:not(:last-child)": { borderBottom: "solid 0.5px #eee" },
        "&:hover": onClick ? { border: 0.5, borderColor: "#6366F1" } : {},
      }}
    >
      <Grid item xs={12} md={2} sx={{ display: "flex" }}>
        {ICONS[rescheduled || item.status] && (
          <>
            <Tooltip title={i18n.t(ICONS[rescheduled || item.status]?.tooltip)}>
              <SvgIcon color={ICONS[rescheduled || item.status]?.color}>
                <Icon />
              </SvgIcon>
            </Tooltip>
            &nbsp;
          </>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {console_out && (
            <Typography variant="body2">
              {item.account.id}. <b>{item.account.name}</b>
            </Typography>
          )}
          <Typography variant="subtitle2" sx={{ fontSize: "80%" }}>
            &nbsp;&nbsp;#{item.uid}
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            {item.repetition_id && (
              <Tooltip title={i18n.t("repeating_item")}>
                <SvgIcon fontSize="80%" sx={{ mr: 1 }}>
                  <ArrowPathRoundedSquareIcon color="gray" />
                </SvgIcon>
              </Tooltip>
            )}
            <Typography variant="body2">
              <I18n l="date.long" v={dayjs(item.date).toDate()} />
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={7}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        {item.amount < 0 && (
          <Typography variant="body1">
            <b>{item.description}</b>
          </Typography>
        )}
        {item.amount < 0 && <div style={{ width: 15 }} />}
        {item.amount < 0 && (
          <SvgIcon>
            <ArrowLongRightIcon />
          </SvgIcon>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 80,
            maxWidth: 80,
            minWidth: 80,
          }}
        >
          <Avatar
            variant="rounded"
            sx={{ width: 28, height: 28, mr: 1 }}
            src={item.bank_account.image}
          />
          <Typography
            variant="body2"
            sx={{ display: { xs: "none", md: "block", whiteSpace: "nowrap" } }}
          >
            {console_out
              ? item.bank_account.name
              : item.bank_account.short_name}
          </Typography>
        </div>
        {item.amount >= 0 && (
          <SvgIcon>
            <ArrowLongRightIcon />
          </SvgIcon>
        )}
        {item.amount >= 0 && <div style={{ width: 15 }} />}
        {item.amount >= 0 && (
          <Typography variant="body1">
            <b>{item.description}</b>
            <div style={{ display: "flex" }}>
              {(item.data?.recipient_name && (
                <Typography
                  variant="subtitle2"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {item.app_integration && (
                    <Tooltip
                      title={i18n.t("created_by_app_integration", {
                        name: item.app_integration.app.name,
                      })}
                    >
                      <Avatar
                        variant="circular"
                        sx={{ width: 16, height: 16 }}
                        src={item.app_integration.app.image}
                      />
                    </Tooltip>
                  )}
                  <SvgIcon fontSize="small">
                    <PaperAirplaneIcon />
                  </SvgIcon>
                  &nbsp;
                  {item.data?.recipient_name}
                </Typography>
              )) || (
                <Typography
                  variant="subtitle2"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {item.app_integration && (
                    <Tooltip
                      title={i18n.t("created_by_app_integration", {
                        name: item.app_integration.app.name,
                      })}
                    >
                      <Avatar
                        variant="circular"
                        sx={{ width: 16, height: 16 }}
                        src={item.app_integration.app.image}
                      />
                    </Tooltip>
                  )}
                  <SvgIcon fontSize="small">
                    <BuildingLibraryIcon />
                  </SvgIcon>
                  &nbsp;
                  <I18n reserve_cash />
                </Typography>
              )}
            </div>
          </Typography>
        )}
      </Grid>
      <Grid item xs={4} md={3} sx={{ textAlign: "right" }}>
        <Typography variant="body1">
          <I18n
            l="currency"
            v={Math.abs(item.amount)}
            currency={item.currency}
          />
        </Typography>
        {item.currency != item.bank_account.currency && (
          <Typography variant="subtitle2">
            {(item.data?.payment_result?.source_amount && (
              <I18n
                l="currency"
                v={item.data.payment_result.source_amount}
                currency={item.bank_account.currency}
              />
            )) ||
              (item.data?.reserve_amount && (
                <I18n
                  l="currency"
                  v={Math.abs(item.data.reserve_amount)}
                  currency={item.bank_account.currency}
                />
              )) ||
              (fx_rates && (
                <>
                  <I18n
                    l="currency"
                    v={
                      Math.abs(item.amount) * fx_rates[item.currency][currency]
                    }
                    currency={item.bank_account.currency}
                  />
                  <I18n estimated />
                </>
              ))}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={1}
        md={1}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {item.pdf_confirmation && (
          <Tooltip
            title={i18n.t("download_bank_statement")}
            sx={{ marginLeft: "auto" }}
          >
            <IconButton
              target="_blank"
              href={routes.confirmation_letter({
                id: item.pdf_confirmation.identifier,
                format: "pdf",
              })}
              onClick={(e) => e.stopPropagation()}
            >
              <SvgIcon>
                <DocumentArrowDownIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        )}
        {console_out && (
          <IconButton
            onClick={() =>
              navigator.clipboard.writeText(
                `${location.protocol}//${location.host}/transactions?popup=${item.bank_account.currency}&uid=${item.uid}`,
              )
            }
          >
            <SvgIcon>
              <ClipboardDocumentIcon />
            </SvgIcon>
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}
