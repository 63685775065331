import React from "react";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import SubmitButton from "./SubmitButton";

export default function ({ open, onClose, title, actions, formRef, children }) {
  return (
    <Modal open={open} onClose={onClose} disablePortal>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          boxShadow: 24,
        }}
      >
        <Card sx={{ p: 2 }}>
          <CardHeader title={title} />
          <Divider />
          <CardContent>{children}</CardContent>
          {children && <Divider />}
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Stack direction="row" spacing={1}>
              {actions &&
                actions
                  .filter((action) => action)
                  .map((action, i) => (
                    <SubmitButton
                      key={i}
                      onClick={action.onClick}
                      href={action.href}
                      variant={action.variant}
                      color={action.color}
                      data-turbo-method={action.method}
                      type={action.type}
                      formRef={formRef}
                      primary={action.primary}
                      forceLoading={action.forceLoading}
                    >
                      {action.label}
                    </SubmitButton>
                  ))}
            </Stack>
          </CardActions>
        </Card>
      </Box>
    </Modal>
  );
}
