import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PopupApplicationLayout } from "../../layouts/PopupApplicationLayout";
import I18n from "../../common/I18n";
import routes from "../../../utils/routes";

export default function ({
  app,
  app_integration,
  app_user_id,
  error,
  signup,
  flash,
}) {
  const onClick = () => {
    if (window.opener?.handleAppIntegrationSelect) {
      window.opener.handleAppIntegrationSelect(app_integration, app_user_id);
      window.close();
    } else if (signup) window.location.href = routes.new_user_session();
    else window.location.href = routes.root();
  };
  const title = error ? "app_error" : signup ? "app_signup" : "app_connected";

  return (
    <PopupApplicationLayout
      title={title}
      titleOpts={{ name: app?.name }}
      flash={flash}
    >
      <Stack spacing={2}>
        {(!error && (
          <Typography variant="body1">
            <I18n app_integration_success name={app_integration.app.name} />
            <br />
          </Typography>
        )) || (
          <Typography variant="body1">
            <I18n t={`app_integration_error_${error}`} />
          </Typography>
        )}
        <div />
        <Button fullWidth variant="contained" onClick={onClick}>
          {signup ? <I18n sign_in /> : <I18n close />}
        </Button>
      </Stack>
    </PopupApplicationLayout>
  );
}
