import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SvgIcon from "@mui/material/SvgIcon";
import Snackbar from "@mui/material/Snackbar";
import { AttentionSeeker } from "react-awesome-reveal";
import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import ChevronRightIcon from "@heroicons/react/24/solid/ChevronRightIcon";
import PlusIcon from "@heroicons/react/24/outline/PlusIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
import dayjs from "dayjs";
import { ApplicationLayout } from "../layouts/ApplicationLayout";
import WarningTooltip from "../common/WarningTooltip";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import TransactionFormDrawer from "./FormDrawer";
import ShowDrawer from "./ShowDrawer";
import TransactionRecord from "./TransactionRecord";
import CashflowItem from "../cashflows/CashflowItem";
import CashflowFormDrawer from "../cashflows/FormDrawer";
import CashflowChart from "./CashflowChart";
import AlertDrawer from "../dashboard/AlertDrawer";
import currencyName from "../../utils/currency-name";

const CURRENCIES = i18n.t("currencies");

export default function ({
  upcoming_cashflow_items,
  scheduled_cashflow_items,
  upcoming_transactions,
  scheduled_transactions,
  ongoing_transactions,
  bank_accounts,
  current_currency,
  popup,
  uid,
  item_uid,
  src_bank_accounts,
  dst_bank_accounts,
  balance_src_bank_accounts,
  balance_dst_bank_accounts,
  fx_rates,
  max_n_days_fund_transfer,
  ...props
}) {
  const {
    inapproval_transactions,
    pending_cashflow_items,
    failed_cashflow_items,
    failed_transactions,
    account_balance_warnings,
    bank_account_warnings,
    current_user,
    account_admin,
  } = props;
  const currencies = CURRENCIES.filter((currency) =>
    [
      ...new Set(
        dst_bank_accounts
          .concat(src_bank_accounts)
          .map((bank_account) => bank_account.currency),
      ),
    ].includes(currency.value),
  ).map((currency) => currency.value);
  const [currencyTab, setCurrencyTab] = useState(
    current_currency || currencies[0],
  );
  const onChangeTab = (_, value) => setCurrencyTab(value);
  const [upcomingOpen, setUpcomingOpen] = useState(popup);
  const [transactionFormOpen, setTransactionFormOpen] = useState(false);
  const [cashflowFormOpen, setCashflowFormOpen] = useState(false);
  const [alertDrawer, setAlertDrawer] = useState(false);
  const [scheduledSelected, setScheduledSelected] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const openUpcoming = (selection) => {
    setSnackbar(false);
    if (scheduledItems.find((item) => item.uid == selection.uid))
      setScheduledSelected(selection);
    else {
      setUpcomingOpen(selection);
      setScheduledSelected(false);
    }
  };
  const closeUpcoming = () => {
    if (window.location.search.length > 0) window.location.search = "";
    setUpcomingOpen(false);
  };
  const openTransactionForm = (transaction) =>
    setTransactionFormOpen(transaction || true);
  const closeTransactionForm = () => {
    if (location.search.length > 1) location.search = "";
    setTransactionFormOpen(false);
  };
  const openCashflowForm = (cashflow_item) =>
    setCashflowFormOpen(cashflow_item || true);
  const closeCashflowForm = () => {
    if (location.search.length > 1) location.search = "";
    setCashflowFormOpen(false);
  };
  const switchToTransactionForm = () => {
    closeCashflowForm();
    openTransactionForm();
  };
  const switchToCashflowForm = () => {
    closeTransactionForm();
    openCashflowForm();
  };
  const openAlertDrawer = () => setAlertDrawer(true);
  const closeAlertDrawer = () => setAlertDrawer(false);
  const openSearch = () => setUpcomingOpen("search");
  const defaultDay = dayjs().add(max_n_days_fund_transfer, "day");
  const scheduled_cashflow_items_currency = scheduled_cashflow_items.filter(
    (item) =>
      item.bank_account.currency == currencyTab && item.status != "canceled",
  );
  const scheduled_transactions_currency = scheduled_transactions.filter(
    (item) =>
      item.status != "canceled" &&
      (item.src_currency == currencyTab || item.dst_currency == currencyTab),
  );
  const upcoming_cashflow_items_currency = upcoming_cashflow_items.filter(
    (item) => item.bank_account.currency == currencyTab,
  );
  const upcoming_transactions_currency = upcoming_transactions.filter(
    (item) =>
      item.src_currency == currencyTab || item.dst_currency == currencyTab,
  );
  const ongoing_transactions_currency = ongoing_transactions.filter(
    (item) =>
      item.src_currency == currencyTab || item.dst_currency == currencyTab,
  );
  const upcomingItemsCount =
    upcoming_cashflow_items_currency.length +
    upcoming_transactions_currency.length;
  const upcomingItems = upcoming_cashflow_items_currency
    .concat(upcoming_transactions_currency)
    .sort((a, b) =>
      (a.date || a.planned_at) > (b.date || b.planned_at) ? 1 : -1,
    );
  const scheduledItems = scheduled_cashflow_items_currency
    .concat(scheduled_transactions_currency)
    .concat(ongoing_transactions_currency)
    .sort((a, b) =>
      (a.date || a.planned_at) > (b.date || b.planned_at) ? 1 : -1,
    );
  const showWarning =
    inapproval_transactions.filter((item) => item.src_currency == currencyTab)
      .length > 0 ||
    pending_cashflow_items.filter(
      (item) => item.bank_account.currency == currencyTab,
    ).length > 0 ||
    failed_cashflow_items.filter(
      (item) => item.bank_account.currency == currencyTab,
    ).length > 0 ||
    failed_transactions.filter(
      (item) =>
        item.src_currency == currencyTab || item.dst_currency == currencyTab,
    ).length > 0 ||
    account_balance_warnings.filter(
      (item) => item.bank_account.currency == currencyTab,
    ).length > 0 ||
    bank_account_warnings.filter(
      (item) => item.bank_account.currency == currencyTab,
    ).length > 0;
  useEffect(() => {
    const item = upcomingItems.find((item) => item.uid == item_uid) ||
      scheduledItems.find((item) => item.uid == item_uid) || { uid: item_uid };
    if (item_uid) {
      setSnackbar(item);
      setTimeout(() => setSnackbar(false), 10000);
    }
  }, []);
  const disabled =
    bank_accounts.filter(
      (_bank_account) => _bank_account.currency == currencyTab,
    ).length == 0;

  return (
    <ApplicationLayout {...props}>
      <Stack spacing={2}>
        <Typography variant="h2">
          <I18n cash_planning />
        </Typography>
        <div style={{ position: "relative" }}>
          <Tabs value={currencyTab} onChange={onChangeTab}>
            {currencies.map((currency) => (
              <Tab
                key={currency}
                value={currency}
                label={currencyName(currency)}
                sx={{ px: 1 }}
              />
            ))}
          </Tabs>
          <IconButton
            onClick={openSearch}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <SvgIcon>
              <MagnifyingGlassIcon />
            </SvgIcon>
          </IconButton>
        </div>
        <Divider sx={{ marginTop: "0!important" }} />
        <div style={{ position: "relative", paddingTop: 8 }}>
          <CashflowChart
            currency={currencyTab}
            upcoming_cashflow_items={upcoming_cashflow_items_currency}
            upcoming_transactions={upcoming_transactions_currency}
            scheduled_cashflow_items={scheduled_cashflow_items_currency}
            scheduled_transactions={scheduled_transactions_currency}
            ongoing_transactions={ongoing_transactions_currency}
            onEditClick={openUpcoming}
            onNewClick={() => openCashflowForm()}
            disabled={disabled}
            fx_rates={fx_rates}
          />
          <Button
            onClick={() => openCashflowForm()}
            variant="contained"
            color="primary"
            disabled={disabled}
            sx={{
              position: "absolute",
              top: 8,
              right: 0,
            }}
          >
            <SvgIcon>
              <PlusIcon />
            </SvgIcon>
            <I18n add />
          </Button>
        </div>

        <Card>
          <CardHeader
            title={i18n.t("scheduled_transactions")}
            subheader={i18n.t("scheduled_transactions_subtitle")}
            action={
              showWarning && (
                <AttentionSeeker effect="flash" delay={2000}>
                  <WarningTooltip
                    arrow
                    placement="left"
                    title={i18n.t("action_required")}
                  >
                    <IconButton onClick={openAlertDrawer} color="error">
                      <SvgIcon>
                        <ExclamationTriangleIcon />
                      </SvgIcon>
                    </IconButton>
                  </WarningTooltip>
                </AttentionSeeker>
              )
            }
          />
          <Divider />
          <CardContent>
            {scheduledItems.map(
              (item, i) =>
                (item.date && (
                  <CashflowItem
                    key={i}
                    item={item}
                    fx_rates={fx_rates}
                    currency={currencyTab}
                    onClick={() => openCashflowForm(item)}
                    selected={
                      scheduledSelected && scheduledSelected.uid == item.uid
                    }
                  />
                )) || (
                  <TransactionRecord
                    key={i}
                    transaction={item}
                    currency={currencyTab}
                    readOnly={current_user.role_value == "read_only"}
                    fx_rates={fx_rates}
                    selected={
                      scheduledSelected && scheduledSelected.uid == item.uid
                    }
                    onClick={
                      item.status == "placed"
                        ? () => openTransactionForm(item)
                        : undefined
                    }
                  />
                ),
            )}
            {scheduledItems.length == 0 && (
              <Typography variant="subtitle2">
                <I18n no_scheduled_automation />
              </Typography>
            )}
          </CardContent>
        </Card>

        <Card onClick={openUpcoming} sx={{ cursor: "pointer" }}>
          <CardHeader
            title={
              <Badge badgeContent={upcomingItemsCount} color="primary">
                {i18n.t("projected_transactions")}
                &nbsp;&nbsp;
              </Badge>
            }
            subheader={i18n.t("projected_transactions_subtitle")}
            action={
              <IconButton onClick={openUpcoming}>
                <SvgIcon>
                  <ChevronRightIcon />
                </SvgIcon>
              </IconButton>
            }
          />
        </Card>
      </Stack>

      <AlertDrawer
        open={alertDrawer}
        onClose={closeAlertDrawer}
        inapproval_transactions={inapproval_transactions}
        pending_cashflow_items={pending_cashflow_items}
        failed_cashflow_items={failed_cashflow_items}
        failed_transactions={failed_transactions}
        account_balance_warnings={account_balance_warnings}
        bank_account_warnings={bank_account_warnings}
        current_user={current_user}
      />
      <ShowDrawer
        open={upcomingOpen}
        selection={upcomingOpen}
        search={upcomingOpen == "search"}
        uid={uid}
        onClose={closeUpcoming}
        fx_rates={fx_rates}
        currency={currencyTab}
        current_user={current_user}
        upcomingItems={upcomingItems}
        openCashflowForm={openCashflowForm}
        openTransactionForm={openTransactionForm}
      />
      <TransactionFormDrawer
        open={!!transactionFormOpen}
        onClose={closeTransactionForm}
        switchToCashflowForm={switchToCashflowForm}
        defaultCurrency={currencyTab}
        item={transactionFormOpen === true ? null : transactionFormOpen}
        fx_rates={fx_rates}
        src_bank_accounts={src_bank_accounts}
        dst_bank_accounts={dst_bank_accounts}
        balance_src_bank_accounts={balance_src_bank_accounts}
        balance_dst_bank_accounts={balance_dst_bank_accounts}
        current_user={current_user}
        account_admin={account_admin}
      />
      <CashflowFormDrawer
        open={!!cashflowFormOpen}
        onClose={closeCashflowForm}
        switchToTransactionForm={switchToTransactionForm}
        item={cashflowFormOpen === true ? null : cashflowFormOpen}
        fx_rates={fx_rates}
        bank_accounts={bank_accounts}
        defaultCurrency={currencyTab}
        defaultDay={defaultDay}
        current_user={current_user}
        account_admin={account_admin}
      />
      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        message={i18n.t("item_updated")}
        action={
          <>
            <Button onClick={() => openUpcoming(snackbar)}>
              <I18n edit />
            </Button>
            <IconButton onClick={() => setSnackbar(false)}>
              <SvgIcon>
                <XMarkIcon />
              </SvgIcon>
            </IconButton>
          </>
        }
      />
    </ApplicationLayout>
  );
}
