import axios from "axios";
import routes from "./routes";

async function api({
  url,
  method,
  headers,
  data,
  onStart,
  onSuccess,
  onError,
}) {
  try {
    data = Object.assign({}, { format: "json" }, data);
    if (onStart) onStart();
    if (
      data &&
      method !== "get" &&
      Object.keys(data).find(
        (key) =>
          !["boolean", "number", "string", "undefined"].includes(
            typeof data[key],
          ),
      )
    ) {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      data = formData;
    }
    const response = await axios.request({
      headers,
      url,
      method,
      data: method == "get" ? undefined : data,
      params: method == "get" ? data : undefined,
    });
    const responseURL = response.request?.responseURL.replace(/\?.*$/, "");
    // redirected to login page
    if (responseURL.endsWith(routes.new_user_session())) location.reload();
    else if (onSuccess) onSuccess(response.data);
  } catch (error) {
    if (onError) onError(error.message);
  }
}

export default api;
