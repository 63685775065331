import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import "simplebar-react/dist/simplebar.min.css";
import { createTheme } from "../theme";
import Flash from "../common/Flash";
import I18n from "../common/I18n";
import logoImage from "images/cashwise-logo.png";

export const PopupApplicationLayout = function ({
  title,
  flash,
  children,
  titleOpts = {},
}) {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        fixed
        sx={{
          height: "100vh",
          maxWidth: "100vw!important",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            width: 500,
            display: "flex",
            mt: 2,
            padding: 5,
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Stack sx={{ mb: 2 }}>
            <Box sx={{ alignSelf: "center" }}>
              <a href="https://cashwise.asia">
                <img src={logoImage} height="86" />
              </a>
            </Box>
            <Typography
              variant="h2"
              sx={{ my: 2, fontSize: "1.5rem", textAlign: "center" }}
            >
              <I18n t={title} {...titleOpts} />
            </Typography>
            <Flash flash={flash} sx={{ my: 2 }} />
          </Stack>
          {children}
        </Paper>
      </Container>
    </ThemeProvider>
  );
};
