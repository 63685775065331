import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { AccountApplicationLayout } from "../../layouts/AccountApplicationLayout";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import NewDrawer from "./NewDrawer";
import EditDrawer from "./EditDrawer";
import ShowDrawer from "./ShowDrawer";
import AppNewDrawer from "../app_integrations/NewDrawer";
import AppEditDrawer from "../app_integrations/EditDrawer";
import BankCard from "./BankCard";
import AppCard from "../app_integrations/AppCard";
import OnboardingSteps from "../../dashboard/OnboardingSteps";
import Confirm from "../../common/Confirm";
import currencyName from "../../../utils/currency-name";

export default function ({
  banks,
  apps,
  bank_accounts,
  app_integrations,
  ask_continue_create_bank_accounts,
  ...props
}) {
  const account = props.account;
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openShow, setOpenShow] = useState(false);
  const [openAppNew, setOpenAppNew] = useState(false);
  const [openAppEdit, setOpenAppEdit] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const onNewClose = () => setOpenNew(false);
  const onNewClick = () => {
    setOpenConfirm(false);
    setOpenNew(true);
  };
  const onEditClose = () => setOpenEdit(false);
  const onEditClick = (bank_account) => setOpenEdit(bank_account);
  const onShowClose = () => setOpenShow(false);
  const onShowClick = (bank_account) => setOpenShow(bank_account);
  const onAppNewClose = () => setOpenAppNew(false);
  const onAppNewClick = () => setOpenAppNew(true);
  const onAppEditClose = () => setOpenAppEdit(false);
  const onAppEditClick = (app_integration) => setOpenAppEdit(app_integration);
  const onConfirmClose = () => setOpenConfirm(false);
  const transactional_bank_accounts = bank_accounts.filter(
    (bank_account) =>
      bank_account.bank_type == "transactional" ||
      bank_account.bank_type == "pseudo_transactional",
  );
  const investment_bank_accounts = bank_accounts.filter(
    (bank_account) => bank_account.bank_type == "investment",
  );
  const investment_portfolio_info = investment_bank_accounts
    .map((bank_account) => bank_account.bank.profile.portfolio_info)
    .flat();
  const get_portfolio_info = (currency) =>
    investment_portfolio_info.find((item) => item.currency == currency);
  useEffect(() => {
    ask_continue_create_bank_accounts &&
      ask_continue_create_bank_accounts.length > 0 &&
      setOpenConfirm(true);
  }, []);

  return (
    <AccountApplicationLayout {...props}>
      <Stack spacing={2}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h3">
            <I18n transactional />
          </Typography>
          {transactional_bank_accounts.length > 0 && (
            <Button
              variant="contained"
              onClick={onNewClick}
              sx={{ px: 4, mx: 4 }}
            >
              + <I18n add />
            </Button>
          )}
        </div>
        {transactional_bank_accounts.map((bank_account) => (
          <BankCard
            key={bank_account.id}
            bank={bank_account}
            suspended={bank_account.status == "suspended"}
            onSelectBank={() => onEditClick(bank_account)}
            action={
              <Switch
                disabled
                defaultChecked={bank_account.status == "active"}
              />
            }
          >
            <Typography variant="body2">
              <I18n currency />
              :&nbsp;&nbsp;&nbsp;
              <b>{currencyName(bank_account.currency)}</b>
            </Typography>
            <Typography
              variant="body2"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <I18n minimum_daily_balance_to_be_maintained />
              :&nbsp;&nbsp;&nbsp;
              <b>
                <I18n
                  l="number"
                  v={bank_account.profile.min_balance}
                  delimiter=","
                  precision={2}
                />
              </b>
            </Typography>
            <Typography
              variant="body2"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <I18n minimum_transfer_amount_between_accounts />
              :&nbsp;&nbsp;&nbsp;
              <b>
                <I18n
                  l="number"
                  v={bank_account.profile.min_transfer}
                  delimiter=","
                  precision={2}
                />
              </b>
            </Typography>
            <Typography
              variant="body2"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <I18n threshold_alert_if_balance_below />
              :&nbsp;&nbsp;&nbsp;
              <b>
                <I18n
                  l="number"
                  v={bank_account.profile.threshold_balance}
                  delimiter=","
                  precision={2}
                />
              </b>
            </Typography>
          </BankCard>
        ))}
        {transactional_bank_accounts.length == 0 && (
          <Button
            fullWidth
            variant="outlined"
            size="large"
            onClick={onNewClick}
          >
            + <I18n add />
          </Button>
        )}
        {investment_bank_accounts.length > 0 && (
          <Typography variant="h3">
            <I18n investment />
          </Typography>
        )}
        {investment_bank_accounts.map((bank_account) => (
          <BankCard
            key={bank_account.id}
            bank={bank_account}
            onSelectBank={() => onShowClick(bank_account)}
          >
            <Typography variant="body2">
              <I18n currency />
              :&nbsp;
              <b>{currencyName(bank_account.currency)}</b>
            </Typography>
            <Typography variant="body2">
              <I18n estimated_yield />
              :&nbsp;
              <b>{get_portfolio_info(bank_account.currency).yield_pa || "-"}</b>
              %p.a
            </Typography>
          </BankCard>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h3">
            <I18n app_integrations />
          </Typography>
          {app_integrations.length > 0 && (
            <Button
              variant="contained"
              onClick={onAppNewClick}
              sx={{ px: 4, mx: 4 }}
            >
              + <I18n add />
            </Button>
          )}
        </div>
        {app_integrations.map((app_integration) => (
          <AppCard
            key={app_integration.id}
            app={app_integration.app}
            app_integration={app_integration}
            disabled={app_integration.status == "disabled"}
            onSelectApp={() => onAppEditClick(app_integration)}
          />
        ))}
        {app_integrations.length == 0 && (
          <Button
            fullWidth
            variant="outlined"
            size="large"
            onClick={onAppNewClick}
          >
            + <I18n add />
          </Button>
        )}
        {investment_bank_accounts.length == 0 && <OnboardingSteps />}
      </Stack>
      <NewDrawer
        open={openNew}
        onClose={onNewClose}
        banks={banks}
        bank_accounts={bank_accounts}
        app_integrations={app_integrations}
      />
      <EditDrawer
        open={!!openEdit}
        onClose={onEditClose}
        bank_account={openEdit}
        investment_bank_accounts={investment_bank_accounts.filter(
          (bank_account) => bank_account.currency == openEdit?.currency,
        )}
      />
      <ShowDrawer
        open={openShow}
        onClose={onShowClose}
        bank_account={openShow}
      />
      <AppNewDrawer
        open={openAppNew}
        onClose={onAppNewClose}
        account={account}
        apps={apps}
        app_integrations={app_integrations}
        bank_accounts={transactional_bank_accounts}
      />
      <AppEditDrawer
        open={!!openAppEdit}
        onClose={onAppEditClose}
        account={account}
        app_integration={openAppEdit}
        bank_accounts={transactional_bank_accounts}
      />
      <Confirm
        open={openConfirm}
        onClose={onConfirmClose}
        title={i18n.t("confirm")}
        actions={[
          {
            onClick: onConfirmClose,
            label: i18n.t("cancel"),
          },
          {
            onClick: onNewClick,
            label: i18n.t("continue"),
            variant: "contained",
          },
        ]}
      >
        <I18n continue_to_setup_another_currency />
      </Confirm>
    </AccountApplicationLayout>
  );
}
