import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import XCircleIcon from "@heroicons/react/24/solid/XCircleIcon";
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import ArrowPathIcon from "@heroicons/react/24/solid/ArrowPathIcon";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import api from "../../../utils/api";
import routes from "../../../utils/routes";
import { numberToCurrency } from "../../../utils/currency-name";
import instructionImage from "../../../../assets/images/cashwise-assistant-instruction.png";

const CASHWISE_ASSISTANT_VERIFIED_KEY = "cashwise_assistant_verified";
const ONE_DAY = 1000 * 60 * 60 * 24;

function Step_1_InstallChromeExtension({ bank_account, nextStep }) {
  return (
    <StepContent>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <Typography variant="subtitle2">
          <I18n
            install_chrome_extension_subtitle
            name={bank_account.short_name}
          />
        </Typography>
        <Button
          variant="contained"
          href={i18n.t("chrome_extension_url")}
          target="_blank"
          rel="noreferrer"
          sx={{ px: 8, mx: 1 }}
        >
          <I18n install />
        </Button>
      </div>
      <Button onClick={nextStep} variant="outlined" fullWidth>
        <I18n next_after_install_extension />
      </Button>
    </StepContent>
  );
}

function Step_2_LoginToBank({ bank_account, nextStep }) {
  return (
    <StepContent>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 16,
        }}
      >
        <Typography variant="subtitle2">
          <I18n
            login_bank_update_balance_subtitle
            name={bank_account.short_name}
          />
        </Typography>
        <img
          src={instructionImage}
          alt="Instruction"
          style={{ width: "90%" }}
        />
      </div>
      <Button
        onClick={nextStep}
        variant="outlined"
        fullWidth
        href={bank_account.bank.description}
        target="_blank"
        rel="noreferrer"
      >
        <I18n />
        <I18n login_to_update name={bank_account.short_name} />
      </Button>
    </StepContent>
  );
}

function Step_3_Verify({ bank_account, prevStep, nextStep, step }) {
  const [verified, setVerified] = useState(false);
  const verifyBalanceUpdate = () => {
    api({
      url: routes.verify_balance_update_account_integration({
        id: bank_account.id,
      }),
      method: "get",
      onSuccess: (data) => {
        const date = new Date(data?.latest_account_balance?.date);
        if (date > new Date() - ONE_DAY) {
          window.removeEventListener("focus", verifyBalanceUpdate);
          localStorage.setItem(CASHWISE_ASSISTANT_VERIFIED_KEY, "true");
          setVerified(
            numberToCurrency(
              data.latest_account_balance?.amount,
              data?.latest_account_balance?.currency,
            ),
          );
        }
      },
    });
  };
  useEffect(() => {
    if (step == 2) window.addEventListener("focus", verifyBalanceUpdate);
  }, [step]);

  return (
    <StepContent>
      {(verified && (
        <Typography
          variant="subtitle2"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <SvgIcon color="success">
            <CheckCircleIcon />
          </SvgIcon>
          &nbsp;
          <I18n balance_verified_subtitle amount={verified} />
        </Typography>
      )) || (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <SvgIcon color="error">
              <XCircleIcon />
            </SvgIcon>
            &nbsp;
            <I18n balance_not_verified_subtitle />
          </Typography>
          <div style={{ margin: "8px 0", whiteSpace: "nowrap" }}>
            <Button variant="outlined" onClick={prevStep}>
              <I18n try_again />
            </Button>
            <Button
              variant="contained"
              onClick={verifyBalanceUpdate}
              sx={{ ml: 1 }}
              startIcon={
                <SvgIcon>
                  <ArrowPathIcon />
                </SvgIcon>
              }
            >
              <I18n verify />
            </Button>
          </div>
        </div>
      )}
      <Button
        onClick={nextStep}
        variant="outlined"
        fullWidth
        disabled={!verified}
        sx={{ mt: 2 }}
      >
        <I18n done />
      </Button>
    </StepContent>
  );
}

function ShowDrawerContent({ bank_account, onClose }) {
  const [step, setStep] = useState(
    localStorage.getItem(CASHWISE_ASSISTANT_VERIFIED_KEY) ? 1 : 0,
  );
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  return (
    <Stepper orientation="vertical" activeStep={step}>
      <Step>
        <StepLabel>
          <I18n install_chrome_extension />
        </StepLabel>
        <Step_1_InstallChromeExtension
          bank_account={bank_account}
          nextStep={nextStep}
        />
      </Step>
      <Step>
        <StepLabel>
          <I18n login_bank_update_balance name={bank_account.short_name} />
        </StepLabel>
        <Step_2_LoginToBank bank_account={bank_account} nextStep={nextStep} />
      </Step>
      <Step>
        <StepLabel>
          <I18n verify_bank_account />
        </StepLabel>
        <Step_3_Verify
          bank_account={bank_account}
          step={step}
          prevStep={prevStep}
          nextStep={onClose}
        />
      </Step>
    </Stepper>
  );
}

export default function (props) {
  const { open, onClose, bank_account } = props;
  if (!bank_account) return null;

  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("instruction_to_update_balance", {
        name: bank_account.short_name,
      })}
      avatar={bank_account.image}
    >
      <ShowDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
