import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import PlusCircleIcon from "@heroicons/react/24/solid/PlusCircleIcon";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import SubmitButton from "../../common/SubmitButton";
import AppCard from "../app_integrations/AppCard";
import AppEditDrawer from "../app_integrations/EditDrawer";
import EditDrawer from "./EditDrawer";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";
import BankCard from "./BankCard";
import currencyName from "../../../utils/currency-name";

function Step1_SelectBank({ banks, bank_accounts, selectBank, onSelectBank }) {
  // If the same bank has already been integrated, obtain the identifier of the credentials.
  const identifier = bank_accounts
    .filter(
      (bank_account) => selectBank && bank_account.bank.id == selectBank.id,
    )
    .map((bank_account) => bank_account.identifier)[0];
  // Open popup window to perform OAuth authentication process
  const popupIntegrationWindow = () => {
    const url = identifier
      ? routes.select_account_integrations({ identifier })
      : routes.initiate_account_integrations({ bank_id: selectBank.id });
    window.open(
      url,
      "CashWise - " + i18n.t("account_integration"),
      [
        "height=700",
        "width=550",
        "menubar=no",
        "toolbar=no",
        "location=no",
        "status=no",
        "resizable=no",
      ].join(","),
    );
  };

  return (
    <StepContent>
      <Stack spacing={2}>
        {banks.map((bank) => (
          <BankCard
            key={bank.id}
            bank={bank}
            select={selectBank}
            onSelectBank={onSelectBank}
          />
        ))}
      </Stack>
      <Box sx={{ mt: 2 }}>
        <SubmitButton
          fullWidth
          disabled={!selectBank}
          variant="contained"
          sx={{ mr: 1 }}
          onClick={popupIntegrationWindow}
        >
          <I18n connect />
        </SubmitButton>
      </Box>
    </StepContent>
  );
}

function Step2_OperationalSettings({
  bank_accounts,
  onEditBankAccount,
  onClose,
  isLastStep,
}) {
  const canProceed = bank_accounts?.some(
    (bank_account) => bank_account.status == "active",
  );

  return (
    <StepContent>
      <Stack spacing={2}>
        {bank_accounts &&
          bank_accounts.map((bank_account) => (
            <BankCard
              key={bank_account.id}
              bank={bank_account}
              onSelectBank={() => onEditBankAccount(bank_account)}
              suspended={bank_account.status == "suspended"}
              action={<Switch checked={bank_account.status == "active"} />}
            >
              <Typography variant="body2">
                <I18n currency />
                :&nbsp;&nbsp;&nbsp;
                <b>{currencyName(bank_account.currency)}</b>
              </Typography>
              {!bank_account.bank.profile.capabilities.includes(
                "get_balance",
              ) && (
                <Typography variant="subtitle2" sx={{ fontSize: "80%" }}>
                  <I18n
                    chrome_extension_balance_update_subtitle
                    name={bank_account.short_name}
                  />
                  <br />
                  <a
                    href={i18n.t("chrome_extension_url")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <I18n chrome_extension_install />
                  </a>
                </Typography>
              )}
            </BankCard>
          ))}
      </Stack>
      <Box sx={{ mt: 2 }}>
        <Button
          onClick={onClose}
          variant="contained"
          fullWidth
          disabled={!canProceed}
        >
          {isLastStep ? <I18n close /> : <I18n next />}
        </Button>
      </Box>
    </StepContent>
  );
}

function Step3_ConfigureAppIntegrations({
  app_integrations,
  onEditAppIntegration,
  onClose,
}) {
  const [openEdit, setOpenEdit] = useState(false);
  const onEdit = (app_integrations) => {
    onEditAppIntegration(app_integrations);
    setOpenEdit(true);
  };

  return (
    <StepContent>
      <Stack spacing={2}>
        {app_integrations.map((app_integration) => (
          <AppCard
            key={app_integration.id}
            app={app_integration.app}
            app_integration={app_integration}
            onSelectApp={() => onEdit(app_integration)}
            action={
              <Switch
                disabled
                defaultChecked={app_integration.status == "enabled"}
              />
            }
          />
        ))}
      </Stack>
      <Box sx={{ mt: 2 }}>
        <Button
          onClick={onClose}
          variant="contained"
          fullWidth
          disabled={!openEdit}
        >
          <I18n close />
        </Button>
      </Box>
    </StepContent>
  );
}

function NewDrawerContent({
  onClose,
  onEditBankAccount,
  banks,
  app_integrations,
  setBankAccounts,
  existingBankAccounts,
  createdBankAccounts,
  onEditAppIntegration,
}) {
  const [step, setStep] = useState(0);
  const [selectBank, setSelectBank] = useState(null);
  const onSelectBank = (bank) =>
    selectBank?.id == bank.id ? setSelectBank(null) : setSelectBank(bank);
  window.handleAccountIntegrationSelect = function (
    _identifier,
    bank_accounts,
  ) {
    setStep(1);
    setBankAccounts(bank_accounts);
  };
  window.handleAccountIntegrationCancel = function () {
    onClose();
  };

  return (
    <Stepper orientation="vertical" activeStep={step}>
      <Step>
        <StepLabel>
          <I18n select_bank />
        </StepLabel>
        <Step1_SelectBank
          banks={banks}
          bank_accounts={existingBankAccounts}
          selectBank={selectBank}
          onSelectBank={onSelectBank}
        />
      </Step>
      <Step>
        <StepLabel>
          <I18n configure_operational_settings />
        </StepLabel>
        <Step2_OperationalSettings
          isLastStep={app_integrations.length == 0}
          bank_accounts={createdBankAccounts}
          onClose={() => (app_integrations.length > 0 ? setStep(2) : onClose())}
          onEditBankAccount={onEditBankAccount}
        />
      </Step>
      {app_integrations.length > 0 && (
        <Step>
          <StepLabel>
            <I18n configure_app_settings />
          </StepLabel>
          <Step3_ConfigureAppIntegrations
            app_integrations={app_integrations}
            onClose={onClose}
            onEditAppIntegration={onEditAppIntegration}
          />
        </Step>
      )}
    </Stepper>
  );
}

export default function (props) {
  const [openEdit, setOpenEdit] = useState(null);
  const [openAppEdit, setOpenAppEdit] = useState(null);
  const [bankAccounts, setBankAccounts] = useState(null);
  const onUpdateBankAccount = (bank_account) => {
    const newBankAccounts = bankAccounts.map((_bank_account) =>
      bank_account.id == _bank_account.id ? bank_account : _bank_account,
    );
    setBankAccounts(newBankAccounts);
    setOpenEdit(null);
  };
  const onEditBankAccount = (bank_account) => setOpenEdit(bank_account);
  const onEditClose = () => setOpenEdit(null);
  const onAppEditClose = () => setOpenAppEdit(null);
  const { open, bank_accounts } = props;
  const onClose = () => location.reload();

  return (
    <>
      <RightDrawerLayout
        open={open}
        onClose={onClose}
        title={i18n.t("integrations")}
        icon={PlusCircleIcon}
      >
        <NewDrawerContent
          {...props}
          existingBankAccounts={bank_accounts}
          createdBankAccounts={bankAccounts}
          setBankAccounts={setBankAccounts}
          onEditAppIntegration={setOpenAppEdit}
          onEditBankAccount={onEditBankAccount}
          onClose={onClose}
        />
      </RightDrawerLayout>
      <AppEditDrawer
        open={!!openAppEdit}
        onClose={onAppEditClose}
        app_integration={openAppEdit}
        bank_accounts={bank_accounts
          .filter((bank_account) => bank_account.bank_type == "transactional")
          .concat(bankAccounts || [])}
      />
      <EditDrawer
        open={!!openEdit}
        onClose={onEditClose}
        bank_account={openEdit}
        onUpdateBankAccount={onUpdateBankAccount}
      />
    </>
  );
}
