import React, { useState, useEffect } from "react";
import BuildingOffice2Icon from "@heroicons/react/24/solid/BuildingOffice2Icon";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import routes from "../../../utils/routes";
import api from "../../../utils/api";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import DocumentTextIcon from "@heroicons/react/24/outline/DocumentTextIcon";
import ArrowRightStartOnRectangleIcon from "@heroicons/react/24/solid/ArrowRightOnRectangleIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import Confirm from "../../common/Confirm";

const SYFE_LOGIN_URL = "https://www.syfe.com/login";

function ShowDrawerContent({ account }) {
  const [response, setResponse] = useState(undefined);
  const [openDelete, setOpenDelete] = useState(false);
  const [trashApp, setTrashApp] = useState(false);
  const toggleOpenDelete = () => setOpenDelete(!openDelete);
  useEffect(() => {
    api({
      method: "get",
      url: routes.console_account({ id: account.id }),
      onSuccess: (_response) => {
        setResponse(_response);
      },
    });
  }, []);
  const deletable =
    response &&
    response.bank_account_balances.filter(
      (balance) =>
        balance.bank_account.bank_type != "investment" &&
        balance.bank_account.status == "active",
    ).length == 0;

  return (
    <Stack spacing={2}>
      {account.profile.asana_task_gid &&
        account.profile.onboarding_status !== "done" && (
          <Typography variant="body2">
            Onboarding tracker: &nbsp;
            <a
              target="_blank"
              href={routes.onboarding({
                id: account.profile.asana_task_gid,
              })}
              rel="noreferrer"
            >
              {routes.onboarding({
                id: account.profile.asana_task_gid,
                with_host: true,
              })}
            </a>
          </Typography>
        )}
      <div>
        {(!account.profile.asana_task_gid ||
          account.profile.onboarding_status == "done") && (
          <Button
            color="warning"
            href={routes.toggle_onboarding_status_console_account({
              id: account.id,
            })}
          >
            Revert Onboarding Status
          </Button>
        )}
        {response && response.app_integrations?.length > 0 && (
          <Button
            href={routes.generate_earning_report_console_account({
              id: account.id,
            })}
          >
            Generate Earning Analysis
          </Button>
        )}
        <Button disabled={!deletable} color="error" onClick={toggleOpenDelete}>
          Delete Account
        </Button>
      </div>
      <Card>
        <CardHeader title="Users" />
        <Divider />
        <CardContent>
          {response?.users.map((user) => (
            <Grid key={user.id} container>
              <Grid item xs={2} md={1} sx={{ py: 1 }}>
                <Avatar
                  src={user.image}
                  variant="circle"
                  sx={{ height: 32, width: 32 }}
                />
              </Grid>
              <Grid
                item
                xs={10}
                md={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body1">
                  <b>{user.name}</b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={5}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body2">{user.email}</Typography>
              </Grid>
              <Grid
                item
                xs={2}
                md={2}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {(user.name === "CashWise Support" && (
                  <Button
                    target="_blank"
                    href={routes.new_user_session({
                      email: user.email,
                      password: user.profile.login_password,
                    })}
                    variant="contained"
                  >
                    Login
                  </Button>
                )) || <Typography variant="body2">{user.role}</Typography>}
              </Grid>
            </Grid>
          ))}
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Bank Accounts" />
        <Divider />
        <Grid container sx={{ my: 2 }}>
          {response && response.bank_account_balances?.length == 0 && (
            <Grid item xs={12} sx={{ p: 4 }}>
              <Typography variant="body1">No bank accounts</Typography>
            </Grid>
          )}
          {response?.bank_account_balances?.map((balance, i) => (
            <Grid
              key={i}
              item
              xs={12}
              md={6}
              sx={{
                px: 3,
                py: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src={balance.bank_account.bank.image}
                  variant="rounded"
                  sx={{ mr: 1, width: 24, height: 24 }}
                />
                <Typography variant="body2">
                  {balance.bank_account.name}
                  {balance.bank_account.status == "suspended" && " (suspended)"}
                </Typography>
                &nbsp;&nbsp;&nbsp;
                {balance.bank_account.bank_type == "investment" && (
                  <IconButton
                    color="primary"
                    target="_blank"
                    href={
                      SYFE_LOGIN_URL +
                      "?account_id=" +
                      balance.bank_account.account_id
                    }
                  >
                    <SvgIcon>
                      <ArrowRightStartOnRectangleIcon />
                    </SvgIcon>
                  </IconButton>
                )}
                {balance.bank_account.pdf_confirmation && (
                  <IconButton
                    target="_blank"
                    href={routes.confirmation_letter({
                      id: balance.bank_account.pdf_confirmation,
                      format: "pdf",
                    })}
                  >
                    <SvgIcon>
                      <DocumentTextIcon />
                    </SvgIcon>
                  </IconButton>
                )}
                {balance.bank_account.bank.profile.capabilities?.includes(
                  "get_account_proof_letter",
                ) && (
                  <IconButton
                    target="_blank"
                    href={routes.get_account_proof_letter_console_account({
                      id: balance.bank_account.id,
                      format: "pdf",
                    })}
                  >
                    <SvgIcon>
                      <DocumentTextIcon />
                    </SvgIcon>
                  </IconButton>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <Typography variant="body2">
                  <b>
                    <I18n
                      l="currency"
                      v={balance.account_balance?.amount || 0}
                      currency={balance.bank_account.currency}
                    />
                  </b>
                </Typography>
                <Typography variant="subtitle2">
                  {balance.account_balance && (
                    <>
                      Updated:&nbsp;
                      <I18n
                        l="date.short"
                        v={balance.account_balance?.updated_at}
                      />
                    </>
                  )}
                </Typography>
              </div>
              {balance.bank_account.bank_type == "transactional" && (
                <div style={{ width: "100%", paddingLeft: 32 }}>
                  <Typography variant="body2">
                    Minimum Target Balance:&nbsp;
                    <b>
                      <I18n
                        l="number"
                        v={balance.bank_account.profile.min_balance}
                        delimiter=","
                        precision={2}
                      />
                    </b>
                    <br />
                    Minimum Transfer Amount:&nbsp;
                    <b>
                      <I18n
                        l="number"
                        v={balance.bank_account.profile.min_transfer}
                        delimiter=","
                        precision={2}
                      />
                    </b>
                    <br />
                    Threshold Balance:&nbsp;
                    <b>
                      <I18n
                        l="number"
                        v={balance.bank_account.profile.threshold_balance}
                        delimiter=","
                        precision={2}
                      />
                    </b>
                  </Typography>
                </div>
              )}
            </Grid>
          ))}
        </Grid>
      </Card>
      <Card>
        <CardHeader title="App Integrations" />
        <Divider />
        <CardContent>
          {response?.app_integrations?.map((app_integration) => (
            <Grid key={app_integration.id} container>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Avatar
                  src={app_integration.app.image}
                  variant="circle"
                  sx={{ height: 32, width: 32, mr: 2 }}
                />
                <Typography variant="body1">
                  <b>{app_integration.app.name}</b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={11}
                md={7}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body2">
                  <ul>
                    <li>
                      Connected to <b>{app_integration.data.company_name}</b>
                    </li>
                    <li>
                      Mode:&nbsp;
                      <b>
                        {app_integration.data.disable_payouts
                          ? "Reserve cash only"
                          : "Enable automated payouts"}
                      </b>
                    </li>
                    <li>
                      Last active at:&nbsp;
                      {i18n.toTime(
                        "time.formats.long",
                        app_integration.last_activity_at,
                      )}
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                md={1}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <IconButton onClick={() => setTrashApp(app_integration.id)}>
                  <SvgIcon>
                    <TrashIcon />
                  </SvgIcon>
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </CardContent>
      </Card>
      <Confirm
        open={openDelete}
        onClose={toggleOpenDelete}
        title="Delete Account"
        actions={[
          {
            onClick: toggleOpenDelete,
            label: "Cancel",
          },
          {
            href: routes.console_account({ id: account.id }),
            color: "error",
            variant: "contained",
            label: "Delete",
            method: "delete",
          },
        ]}
      />
      <Confirm
        open={trashApp}
        onClose={() => setTrashApp(false)}
        title="Disconnect App Integration"
        actions={[
          {
            onClick: () => setTrashApp(false),
            label: "Cancel",
          },
          {
            href: routes.disconnect_app_console_account({
              id: trashApp,
            }),
            color: "error",
            variant: "contained",
            label: "Disconnect",
            method: "get",
          },
        ]}
      />
    </Stack>
  );
}

export default function (props) {
  const { open, onClose, account } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={account.name}
      icon={BuildingOffice2Icon}
    >
      <ShowDrawerContent {...props} onClose={onClose} />
    </RightDrawerLayout>
  );
}
