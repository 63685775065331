import dayjs from "dayjs";

// calculate the date after a number of working days
export default function (date, days) {
  const delta = days > 0 ? 1 : -1;
  let workingDays = 0;
  let currentDate = dayjs(date).startOf("day");

  while (workingDays < Math.abs(days)) {
    currentDate = currentDate.add(delta, "day");
    if (currentDate.day() !== 0 && currentDate.day() !== 6) workingDays++;
  }
  return currentDate;
}
