import React, { useState, useRef, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import I18n from "../../common/I18n";
import { AnalysisApplicationLayout } from "../../layouts/AnalysisApplicationLayout";
import { Form } from "../../common/Form";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SubmitButton from "../../common/SubmitButton";
import currencyName from "../../../utils/currency-name";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";
import api from "../../../utils/api";

function IntegrationCard({
  integration,
  selected,
  onSelect,
  comingSoon,
  children,
}) {
  return (
    <Paper
      onClick={
        onSelect && !comingSoon ? () => onSelect(integration) : undefined
      }
      sx={{
        p: 2,
        ...(comingSoon && { opacity: 0.7, backgroundColor: "#f5f5f5" }),
        ...(selected && { border: 2, borderColor: "#6366F1" }),
        ...(onSelect && {
          cursor: "pointer",
          "&:hover": { border: 0.5, borderColor: "#6366F1" },
        }),
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Avatar
          src={integration?.image || integration?.app?.image}
          variant="rounded"
          sx={{ height: 56, width: 56, alignSelf: "flex-start" }}
        />
        <div style={{ marginLeft: 24, marginRight: "auto" }}>
          <Typography variant="h6" sx={{ display: "block" }}>
            {integration?.name || integration?.app?.name}
          </Typography>
        </div>
        {comingSoon && <Chip size="small" label={i18n.t("coming_soon")} />}
        {children && (
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            <I18n connected />
          </Typography>
        )}
      </div>
      {children && <Divider sx={{ my: 2 }} />}
      {children}
    </Paper>
  );
}

function Step1_SelectIntegration({ apps, banks, resetRef }) {
  const [selectApp, setSelectApp] = useState(null);
  const popupIntegrationWindow = () => {
    window.open(
      routes.initiate_account_app_integrations({
        app_id: selectApp.id,
        type: "analysis",
      }),
      "CashWise - " + i18n.t("account_integration"),
      [
        "height=700",
        "width=550",
        "menubar=no",
        "toolbar=no",
        "location=no",
        "status=no",
        "resizable=no",
      ].join(","),
    );
  };

  return (
    <>
      <Typography
        variant="h2"
        sx={{ my: 6, fontSize: "1.5rem", textAlign: "center" }}
      >
        <I18n choose_data_source />
      </Typography>
      <Stack spacing={2} sx={{ mb: 6 }}>
        {apps.map((app, i) => (
          <IntegrationCard
            integration={app}
            key={i}
            onSelect={setSelectApp}
            selected={selectApp && selectApp.id === app.id}
          />
        ))}
        {banks.map((bank, i) => (
          <IntegrationCard integration={bank} key={i} comingSoon />
        ))}
        <div />
        <div />
        <SubmitButton
          fullWidth
          disabled={!selectApp}
          resetRef={resetRef}
          variant="contained"
          sx={{ m: 2 }}
          onClick={popupIntegrationWindow}
        >
          <I18n continue />
        </SubmitButton>
      </Stack>
    </>
  );
}

const SCHEMA = yup.object({
  company_name: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { field: "company_name" })),
  acknowledgement: yup.bool().required(),
});

function Step2_SelectAppBankAccounts({ appIntegration }) {
  const [appBankAccounts, setAppBankAccounts] = useState([]);
  const [retrievingBankAccounts, setRetrievingBankAccounts] = useState(false);
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company_name: appIntegration.data.company_name,
      duration: 12,
    },
    resolver: yupResolver(SCHEMA),
  });
  const retrieveBankAccounts = () => {
    if (appIntegration)
      api({
        url: routes.retrieve_bank_accounts_account_app_integrations(),
        method: "get",
        data: { identifier: appIntegration.identifier, format: "json" },
        onStart: () => setRetrievingBankAccounts(true),
        onError: () => setRetrievingBankAccounts(false),
        onSuccess: (app_bank_accounts) => {
          setAppBankAccounts(
            app_bank_accounts.sort((a, b) => a.name.localeCompare(b.name)),
          );
          setRetrievingBankAccounts(false);
        },
      });
  };
  useEffect(() => {
    retrieveBankAccounts();
  }, [appIntegration]);

  return (
    <Form
      action={routes.analysis_submissions({
        type: "AppIntegration",
        identifier: appIntegration.identifier,
      })}
      method="post"
    >
      <input type="hidden" name="name" value={appIntegration.data.name} />
      <input type="hidden" name="email" value={appIntegration.data.email} />
      <Typography
        variant="h2"
        sx={{ my: 6, fontSize: "1.5rem", textAlign: "center" }}
      >
        <I18n select_accounts_analysis />
      </Typography>
      <Stack spacing={2} sx={{ mb: 6 }}>
        <TextField
          fullWidth
          required
          variant="outlined"
          label={i18n.t("company_name")}
          helperText={errors.company_name?.message}
          {...register("company_name")}
          error={"company_name" in errors}
          autoFocus
        />
        <IntegrationCard integration={appIntegration}>
          <Stack spacing={1}>
            {retrievingBankAccounts && (
              <CircularProgress
                sx={{ marginLeft: "auto", marginRight: "auto" }}
              />
            )}
            {appBankAccounts.map((bankAccount, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">
                  {bankAccount.name} - {currencyName(bankAccount.currency)}
                </Typography>
                <Switch name={`bank_accounts[${i}][enabled]`} defaultChecked />
                <input
                  type="hidden"
                  name={`bank_accounts[${i}][name]`}
                  value={bankAccount.name}
                />
                <input
                  type="hidden"
                  name={`bank_accounts[${i}][currency]`}
                  value={bankAccount.currency}
                />
                <input
                  type="hidden"
                  name={`bank_accounts[${i}][account_id]`}
                  value={bankAccount.account_id}
                />
              </div>
            ))}
          </Stack>
        </IntegrationCard>
        <div />
        <FormGroup>
          <FormControlLabel
            control={<CheckBox required />}
            label={
              <Typography variant="subtitle2">
                {i18n.t("analysis_acknowledgement")}
              </Typography>
            }
          />
        </FormGroup>
        <SubmitButton fullWidth variant="contained" type="submit">
          <I18n generate />
        </SubmitButton>
      </Stack>
    </Form>
  );
}

export default function ({ banks, apps }) {
  const [step, setStep] = useState(0);
  const resetRef = useRef();
  const [appIntegration, setAppIntegration] = useState(null);
  useEffect(() => {
    window.handleAppIntegrationSelect = function (app_integration) {
      if (app_integration) {
        setAppIntegration(app_integration);
        setStep(1);
      } else {
        setAppIntegration(null);
        resetRef.current();
      }
    };
  }, []);

  return (
    <AnalysisApplicationLayout>
      {step === 0 && (
        <Step1_SelectIntegration
          apps={apps}
          banks={banks}
          resetRef={resetRef}
        />
      )}
      {step === 1 && appIntegration && (
        <Step2_SelectAppBankAccounts appIntegration={appIntegration} />
      )}
    </AnalysisApplicationLayout>
  );
}
