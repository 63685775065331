import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import { DateCalendar } from "@mui/x-date-pickers";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import PlusCircleIcon from "@heroicons/react/24/solid/PlusCircleIcon";
import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RightDrawerLayout } from "../layouts/RightDrawerLayout";
import { Form } from "../common/Form";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import routes from "../../utils/routes";
import dayjs from "dayjs";
import Dropdown from "../common/Dropdown";
import SubmitButton from "../common/SubmitButton";
import Confirm from "../common/Confirm";
import NumberInput from "../common/NumberInput";
import currencyName from "../../utils/currency-name";

const CURRENCIES = i18n.t("currencies");
const SCHEMA = yup.object({
  amount: yup
    .number()
    .transform((_, value) =>
      typeof value == "string" ? parseFloat(value.replace(/,/g, "")) : value,
    )
    .positive(i18n.t("this_field_must_be_positive"))
    .required(i18n.t("field_cannot_be_blank", { model: i18n.t("amount") })),
  src_bank_account_id: yup
    .number()
    .required(
      i18n.t("field_cannot_be_blank", { field: i18n.t("bank_account_src") }),
    ),
  dst_bank_account_id: yup
    .number()
    .required(
      i18n.t("field_cannot_be_blank", { field: i18n.t("bank_account_dst") }),
    ),
});

const REPETITIONS = [
  { value: "none", name: i18n.t("no_repetition") },
  { value: "every_month", name: i18n.t("same_day_every_month") },
  { value: "every_quarter", name: i18n.t("same_day_every_quarter") },
  { value: "every_year", name: i18n.t("same_day_every_year") },
];

function bankAccountName(bank_account, balance_bank_accounts) {
  const balance_bank_account = balance_bank_accounts.find(
    (_balance) => _balance?.bank_account_id == bank_account.id,
  );

  if (balance_bank_account) {
    const amount_display = i18n.numberToCurrency(balance_bank_account.amount, {
      precision: 2,
      delimiter: ",",
      unit: currencyName(bank_account.currency),
    });
    return `${bank_account.short_name} - ${amount_display}`;
  } else return bank_account.name;
}

function FormDrawerContent({
  onClose,
  switchToCashflowForm,
  item,
  fx_rates,
  src_bank_accounts,
  dst_bank_accounts,
  balance_src_bank_accounts,
  balance_dst_bank_accounts,
  current_user,
  account_admin,
}) {
  const params = new URL(location.href).searchParams;
  const disabled =
    ["completed"].includes(item?.status) ||
    current_user.role_value == "read_only";
  const repeatingInputRef = useRef();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const onClickDelete = () => setConfirmDelete(true);
  const onCloseDelete = () => setConfirmDelete(false);
  const onClickUpdate = () => setConfirmUpdate(true);
  const onCloseUpdate = () => setConfirmUpdate(false);
  const transaction_data = item?.transaction_data;
  const srcBankAccountsValues = (
    item
      ? [item.src_bank_account]
      : src_bank_accounts.filter((bank_account) =>
          bank_account.profile?.supported_fx_withdrawals?.find(
            (supported_fx) => supported_fx.from == bank_account.currency,
          ),
        )
  ).map((bank_account) => ({
    value: bank_account.id,
    name: bankAccountName(bank_account, balance_src_bank_accounts),
    currency: bank_account.currency,
  }));
  const dstBankAccountsValues = (
    item
      ? [item.dst_bank_account]
      : dst_bank_accounts.filter((bank_account) =>
          src_bank_accounts
            .map((_bank_account) =>
              _bank_account.profile?.supported_fx_withdrawals?.map(
                (supported_fx) => supported_fx.to,
              ),
            )
            .flat()
            .includes(bank_account.currency),
        )
  ).map((bank_account) => ({
    value: bank_account.id,
    name: bankAccountName(bank_account, balance_dst_bank_accounts),
    currency: bank_account.currency,
  }));
  const default_srcBankAccountValue = srcBankAccountsValues[0];
  const default_dstBankAccountValue = dstBankAccountsValues[0];
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      src_bank_account_id:
        item?.src_bank_account_id || default_srcBankAccountValue?.value,
      dst_bank_account_id:
        item?.dst_bank_account_id || default_dstBankAccountValue?.value,
      amount: item?.dst_amount || 1000,
      date: item?.completed_at || params.get("date") || dayjs(),
      frequency: item?.repetition?.frequency,
    },
    resolver: yupResolver(SCHEMA),
  });
  const amount = watch("amount");
  const src_bank_account_id = watch("src_bank_account_id");
  const dst_bank_account_id = watch("dst_bank_account_id");
  const srcCurrency = CURRENCIES.find(
    (_currency) =>
      _currency.value ==
      (item?.src_bank_account?.currency ||
        srcBankAccountsValues.find(
          (bank_account_value) =>
            bank_account_value.value == src_bank_account_id,
        )?.currency),
  );
  const dstCurrency = CURRENCIES.find(
    (_currency) =>
      _currency.value ==
      (item?.dst_bank_account?.currency ||
        dstBankAccountsValues.find(
          (bank_account_value) =>
            bank_account_value.value == dst_bank_account_id,
        )?.currency),
  );
  const src_bank_account =
    item?.src_bank_account ||
    src_bank_accounts.find(
      (_bank_account) => _bank_account.id == src_bank_account_id,
    );
  let defaultDay =
    (params.get("date") && dayjs(params.get("date"))) || dayjs().add(3, "day");
  while (defaultDay.day() == 0 || defaultDay.day() == 6) {
    defaultDay = defaultDay.add(1, "day");
  }

  return (
    <Stack spacing={2}>
      <Form
        action={
          item ? routes.transaction({ id: item.id }) : routes.transactions()
        }
        method={item ? "put" : "post"}
        onKeyDown={(event) => {
          if (event.key == "Enter") event.preventDefault();
        }}
        onSubmit={(event) =>
          handleSubmit(
            () => undefined,
            () => event.preventDefault(),
          )()
        }
      >
        <Card>
          <CardContent>
            {(srcBankAccountsValues.length > 0 &&
              dstBankAccountsValues.length > 0 && (
                <Stack spacing={2}>
                  <DateCalendar
                    disabled={disabled}
                    minDate={dayjs().add(1, "day")}
                    defaultValue={
                      item?.completed_at ? dayjs(item.completed_at) : defaultDay
                    }
                    onChange={(date) =>
                      setValue("date", date.format("YYYY-MM-DD"))
                    }
                  />
                  <input type="hidden" {...register("date")} />
                  {!item && (
                    <Button
                      variant="text"
                      onClick={switchToCashflowForm}
                      sx={{ marginRight: "auto!important" }}
                    >
                      <SvgIcon>
                        <ChevronLeftIcon />
                      </SvgIcon>
                      <I18n schedule_cashflow />
                    </Button>
                  )}
                  <Dropdown
                    fullWidth
                    disabled={disabled}
                    variant="standard"
                    label={i18n.t("integration_src")}
                    values={srcBankAccountsValues}
                    readOnly={!!item}
                    defaultValue={
                      item?.src_bank_account_id ||
                      default_srcBankAccountValue?.value
                    }
                    helperText={errors.src_bank_account_id?.message}
                    {...register("src_bank_account_id")}
                    error={"src_bank_account_id" in errors}
                  />
                  <Dropdown
                    fullWidth
                    disabled={disabled}
                    variant="standard"
                    label={i18n.t("integration_dst")}
                    values={dstBankAccountsValues}
                    readOnly={!!item}
                    defaultValue={
                      item?.dst_bank_account_id ||
                      default_dstBankAccountValue?.value
                    }
                    helperText={
                      errors.dst_bank_account_id?.message ||
                      (!dstBankAccountsValues.find(
                        (bank_account) =>
                          bank_account.value == dst_bank_account_id,
                      ) &&
                        i18n.t("field_cannot_be_blank", {
                          field: i18n.t("integration_dst"),
                        }))
                    }
                    {...register("dst_bank_account_id")}
                    error={
                      !dstBankAccountsValues.find(
                        (bank_account) =>
                          bank_account.value == dst_bank_account_id,
                      ) || "dst_bank_account_id" in errors
                    }
                  />
                  <TextField
                    fullWidth
                    required
                    disabled={disabled}
                    variant="standard"
                    InputProps={{
                      inputComponent: NumberInput,
                      inputProps: {
                        value: amount,
                        ...register("amount"),
                      },
                    }}
                    label={i18n.t("dst_amount") + " - " + dstCurrency.name}
                    helperText={errors.amount?.message}
                    error={"amount" in errors}
                  />
                  <Dropdown
                    fullWidth
                    disabled={disabled}
                    variant="standard"
                    label={i18n.t("repeat")}
                    helperText={errors.repeat?.message}
                    defaultValue={item?.repetition?.frequency || "none"}
                    values={REPETITIONS}
                    {...register("frequency")}
                    error={"frequency" in errors}
                  />
                  {srcCurrency.value != dstCurrency.value && (
                    <Typography variant="subtitle2">
                      <I18n
                        automation_fx_estimation
                        currency={srcCurrency.name}
                        amount={i18n.numberToRounded(
                          amount *
                            fx_rates[dstCurrency.value][srcCurrency.value],
                          { precision: 2, delimiter: "," },
                        )}
                      />
                      <br />
                      <I18n automation_fx_conversion_subtitle />
                      <br />
                      {src_bank_account.profile.enable_trailing_stop && (
                        <div style={{ marginTop: 12 }}>
                          <I18n
                            automation_fx_conversion_early_execute_subtitle
                          />
                          <br />
                          <I18n
                            current_exchange_rate_subtitle
                            src_currency={srcCurrency.name}
                            dst_currency={dstCurrency.name}
                            rate={i18n.numberToRounded(
                              fx_rates[srcCurrency.value][dstCurrency.value],
                              { precision: 6, delimiter: "," },
                            )}
                          />
                          {transaction_data?.highest_fx_rate && (
                            <>
                              , &nbsp;
                              <I18n
                                trailing_stop_trigger_rate_subtitle
                                src_currency={srcCurrency.name}
                                dst_currency={dstCurrency.name}
                                rate={i18n.numberToRounded(
                                  (1.0 -
                                    src_bank_account.profile
                                      .trailing_stop_rate /
                                      100.0) *
                                    transaction_data.highest_fx_rate,
                                  { precision: 6, delimiter: "," },
                                )}
                              />
                            </>
                          )}
                          <br />
                          <I18n
                            click_here_configure_fx_settings
                            click_here={routes.account_currencies()}
                          />
                        </div>
                      )}
                    </Typography>
                  )}
                </Stack>
              )) || (
              <Typography variant="subtitle2">
                <I18n no_integrations_for_fx />
                &nbsp;
                {account_admin && (
                  <I18n
                    click_here_account_integration
                    click_here={routes.account_integrations()}
                  />
                )}
              </Typography>
            )}
          </CardContent>
        </Card>
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
          {(srcBankAccountsValues.length == 0 ||
            dstBankAccountsValues.length == 0 ||
            disabled) && (
            <Button variant="contained" onClick={onClose}>
              <I18n close />
            </Button>
          )}
          {srcBankAccountsValues.length > 0 &&
            dstBankAccountsValues.length > 0 &&
            !disabled &&
            ((!item?.repetition && (
              <SubmitButton fullWidth variant="contained" type="submit">
                {(!item && <I18n create />) || <I18n update />}
              </SubmitButton>
            )) || (
              <Button fullWidth variant="contained" onClick={onClickUpdate}>
                <I18n update />
              </Button>
            ))}
          {!disabled && item && (
            <Button
              onClick={onClickDelete}
              sx={{ mt: 1, alignSelf: "flex-end" }}
              color="error"
            >
              <I18n delete />
            </Button>
          )}
          <Confirm
            open={confirmDelete}
            onClose={onCloseDelete}
            title={i18n.t("delete_transaction")}
            actions={[
              {
                onClick: onCloseDelete,
                label: i18n.t("cancel"),
              },
              item?.repetition
                ? {
                    href: routes.transaction({
                      id: item?.id,
                      repeating: "all_after_this",
                    }),
                    color: "error",
                    variant: "outlined",
                    label: i18n.t("delete_after_this"),
                    method: "delete",
                  }
                : null,
              {
                href: routes.transaction({ id: item?.id }),
                color: "error",
                variant: "contained",
                label: i18n.t("delete"),
                method: "delete",
              },
            ]}
          >
            <I18n this_action_cannot_be_undone />
          </Confirm>
          {item?.repetition && (
            <Confirm
              open={confirmUpdate}
              onClose={onCloseUpdate}
              title={i18n.t("update_repeating_items")}
              actions={[
                {
                  label: i18n.t("update_this_only"),
                  onClick: () =>
                    (repeatingInputRef.current.value = "only_this"),
                  type: "submit",
                },
                {
                  label: i18n.t("update_all_after_this"),
                  variant: "contained",
                  onClick: () =>
                    (repeatingInputRef.current.value = "all_after_this"),
                  type: "submit",
                },
              ]}
            ></Confirm>
          )}
          <input type="hidden" name="repeating" ref={repeatingInputRef} />
        </Box>
      </Form>
    </Stack>
  );
}

export default function (props) {
  const { open, onClose, item } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("fx_transaction") + (item ? ` #${item.uid}` : "")}
      icon={item ? PencilIcon : PlusCircleIcon}
    >
      <FormDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
