import React from "react";
import { RootApplicationLayout } from "../../layouts/RootApplicationLayout";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import { Form } from "../../common/Form";
import routes from "../../../utils/routes";

const SCHEMA = yup.object({
  name: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { model: i18n.t("name") })),
  password: yup
    .string()
    .required(i18n.t("password_must_be_at_least_12_characters"))
    .min(12, i18n.t("password_must_be_at_least_12_characters"))
    .matches(
      /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/,
      i18n.t("password_must_be_at_least_12_characters"),
    ),
  password_confirmation: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      i18n.t("you_have_to_type_same_password"),
    ),
});

export default function ({ flash, user, invitation_token }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: user.name,
      password: "",
      password_confirmation: "",
    },
    resolver: yupResolver(SCHEMA),
  });

  return (
    <RootApplicationLayout title="welcome" flash={flash}>
      <Form
        action={routes.user_invitation()}
        method="put"
        onSubmit={(event) =>
          handleSubmit(
            () => undefined,
            () => event.preventDefault(),
          )()
        }
      >
        <input type="hidden" name="invitation_token" value={invitation_token} />
        <Stack spacing={2}>
          <Typography variant="h3">
            <I18n sign_up />
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            label={i18n.t("email_address")}
            value={user.email}
            readOnly
          />
          <TextField
            fullWidth
            required
            variant="standard"
            label={i18n.t("user_name")}
            helperText={errors.name?.message}
            error={"name" in errors}
            {...register("name")}
            autoFocus={true}
            autoComplete="email"
          />
          <TextField
            fullWidth
            required
            type="password"
            variant="standard"
            label={i18n.t("password")}
            helperText={errors.password?.message}
            error={"password" in errors}
            {...register("password")}
          />
          <TextField
            fullWidth
            required
            type="password"
            variant="standard"
            label={i18n.t("password_confirm")}
            helperText={errors.password_confirmation?.message}
            error={"password_confirmation" in errors}
            {...register("password_confirmation")}
          />
          <Button type="submit" variant="contained" size="large">
            <I18n sign_up />
          </Button>
        </Stack>
      </Form>
    </RootApplicationLayout>
  );
}
