import React, { useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import SvgIcon from "@mui/material/SvgIcon";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { RightDrawerLayout } from "../layouts/RightDrawerLayout";
import ChevronDownIcon from "@heroicons/react/24/solid/ChevronDownIcon";
import ChevronRightIcon from "@heroicons/react/24/solid/ChevronRightIcon";
import i18n from "../../utils/i18n";
import I18n from "../common/I18n";
import InstructionDrawer from "../account/integrations/InstructionDrawer";
import TransactionRecord from "../transactions/TransactionRecord";
import CashflowItem from "../cashflows/CashflowItem";
import api from "../../utils/api";
import routes from "../../utils/routes";

const SYFE_LOGIN = "https://www.syfe.com/login?extension=on";

function InfoGrid({ name, value }) {
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant="subtitle2">
          <I18n t={name} />
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2">
          <b>{value}</b>
        </Typography>
      </Grid>
    </Grid>
  );
}

function ShowDrawerContent({
  bank_account,
  currency,
  fx_rates,
  current_user,
  last_updated_at,
  openCashflowForm,
  openTransactionForm,
  pastItems,
}) {
  const [oldItems, setOldItems] = useState(undefined);
  const [retrievingOldItems, setRetrievingOldItems] = useState(false);
  const [openDepositInfo, setOpenDepositInfo] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);
  const onCloseShowInstruction = () => setShowInstruction(false);
  const toggleDepositInfo = () => setOpenDepositInfo(!openDepositInfo);
  const retrieveOldItems = () => {
    api({
      url: routes.account_trends({
        past_items: true,
        bank_account_id: bank_account.id,
      }),
      method: "get",
      onStart: () => setRetrievingOldItems(true),
      onError: () => setRetrievingOldItems(false),
      onSuccess: (data) => {
        setRetrievingOldItems(false);
        const items = data.past_cashflow_items
          .concat(data.past_transactions)
          .sort((a, b) =>
            (a.date || a.planned_at) > (b.date || b.planned_at) ? -1 : 1,
          );
        setOldItems(items);
      },
    });
  };

  return (
    <Stack spacing={2}>
      {bank_account.bank_type == "transactional" &&
        !bank_account.profile.capabilities?.includes("get_balance") && (
          <Card>
            <CardContent
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography variant="subtitle2" sx={{ fontSize: "80%", mr: 1 }}>
                {(last_updated_at && (
                  <I18n
                    balance_update_instruction_subtitle_1
                    name={bank_account.short_name}
                    date={i18n.toTime("date.formats.short", last_updated_at)}
                  />
                )) || (
                  <I18n
                    balance_update_instruction_subtitle_2
                    name={bank_account.short_name}
                  />
                )}
                &nbsp;
                <I18n balance_update_instruction_subtitle_3 />
              </Typography>
              <Button
                variant="outlined"
                sx={{ ml: 1 }}
                onClick={() => setShowInstruction(true)}
              >
                <I18n show_instructions />
              </Button>
            </CardContent>
          </Card>
        )}
      {bank_account.bank_type == "investment" && (
        <Card>
          <CardContent
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="subtitle2" sx={{ fontSize: "80%" }}>
              <I18n chrome_extension_subtitle name={bank_account.short_name} />
              <br />
              <a
                href={i18n.t("chrome_extension_url")}
                target="_blank"
                rel="noreferrer"
              >
                <I18n chrome_extension_install />
              </a>
            </Typography>
            <Button variant="contained" href={SYFE_LOGIN} target="_blank">
              <I18n login_to name={bank_account.short_name} />
            </Button>
          </CardContent>
        </Card>
      )}
      {bank_account.bank_type == "investment" && (
        <Card>
          <CardHeader
            title={
              <>
                <IconButton onClick={toggleDepositInfo}>
                  <SvgIcon>
                    {openDepositInfo ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </SvgIcon>
                </IconButton>
                <I18n deposit_bank_account_info />
              </>
            }
            subheader={
              openDepositInfo && i18n.t("deposit_bank_account_subtitle")
            }
          />
          {openDepositInfo &&
            bank_account.profile.bank_account_info.map(
              (bank_account_info, i) => (
                <>
                  <Divider />
                  <CardContent key={i}>
                    <Stack spacing={2}>
                      <InfoGrid
                        name="bank_name"
                        value={bank_account_info.bank_name}
                      />
                      <InfoGrid
                        name="bank_code"
                        value={bank_account_info.bank_code}
                      />
                      <InfoGrid
                        name="bank_swift_code"
                        value={bank_account_info.bank_swift_code}
                      />
                      <InfoGrid
                        name="bank_account_number"
                        value={bank_account_info.bank_account_number}
                      />
                      <InfoGrid
                        name="bank_account_name"
                        value={bank_account_info.company_name}
                      />
                      <InfoGrid
                        name="bank_reference"
                        value={bank_account.profile.reference}
                      />
                    </Stack>
                  </CardContent>
                </>
              ),
            )}
        </Card>
      )}
      <Card>
        <CardContent>
          {pastItems.map(
            (item, i) =>
              (item.date && (
                <CashflowItem
                  key={i}
                  item={item}
                  currency={currency}
                  fx_rates={fx_rates}
                  onClick={() => openCashflowForm(item)}
                />
              )) || (
                <TransactionRecord
                  key={i}
                  transaction={item}
                  currency={currency}
                  readOnly={current_user.role_value == "read_only"}
                  fx_rates={fx_rates}
                  onClick={
                    item.status == "placed"
                      ? () => openTransactionForm(item)
                      : undefined
                  }
                />
              ),
          )}
          {oldItems &&
            oldItems.map(
              (item, i) =>
                (item.date && (
                  <CashflowItem
                    key={i}
                    item={item}
                    currency={currency}
                    fx_rates={fx_rates}
                    onClick={() => openCashflowForm(item)}
                  />
                )) || (
                  <TransactionRecord
                    key={i}
                    transaction={item}
                    currency={currency}
                    readOnly={current_user.role_value == "read_only"}
                    fx_rates={fx_rates}
                    onClick={
                      item.status == "placed"
                        ? () => openTransactionForm(item)
                        : undefined
                    }
                  />
                ),
            )}
          {pastItems.length + (oldItems?.length || 0) == 0 && (
            <I18n no_automation />
          )}
          {!oldItems && (
            <Button
              fullWidth
              sx={{ mt: 1 }}
              onClick={() => retrieveOldItems()}
              disabled={retrievingOldItems}
            >
              <I18n show_more />
            </Button>
          )}
        </CardContent>
      </Card>
      <InstructionDrawer
        bank_account={bank_account}
        open={showInstruction}
        onClose={onCloseShowInstruction}
      />
    </Stack>
  );
}

export default function (props) {
  const { open, onClose, bank_account } = props;

  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={bank_account.name}
      avatar={bank_account.image}
    >
      <ShowDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
