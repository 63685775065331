import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import TextField from "@mui/material/TextField";
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import { RightDrawerLayout } from "../layouts/RightDrawerLayout";
import i18n from "../../utils/i18n";
import I18n from "../common/I18n";
import TransactionRecord from "../transactions/TransactionRecord";
import CashflowItem from "../cashflows/CashflowItem";
import api from "../../utils/api";
import routes from "../../utils/routes";

function SearchDrawerContent({
  currency,
  fx_rates,
  current_user,
  openCashflowForm,
  openTransactionForm,
  pastItems,
}) {
  const [searchItems, setSearchItems] = useState(pastItems);
  const [searchText, setSearchText] = useState("");
  const reset = () => {
    setSearchItems(pastItems);
    setSearchText("");
  };
  const retrieveSearchItems = (e) => {
    e.preventDefault();
    api({
      url: routes.account_trends({
        currency,
        search_text: searchText,
      }),
      method: "get",
      onSuccess: (data) => {
        console.log("data", data);
        const items = data.search_cashflow_items
          .concat(data.search_transactions)
          .sort((a, b) =>
            (a.date || a.planned_at) > (b.date || b.planned_at) ? -1 : 1,
          );
        setSearchItems(items);
      },
    });
  };

  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader
          title={
            <form
              style={{ display: "flex", alignItems: "center" }}
              onSubmit={retrieveSearchItems}
            >
              <TextField
                autoFocus
                fullWidth
                autoComplete="off"
                label={i18n.t("search")}
                variant="standard"
                name="search_text"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
              />
              <IconButton type="submit">
                <SvgIcon>
                  <MagnifyingGlassIcon />
                </SvgIcon>
              </IconButton>
              <IconButton onClick={reset}>
                <SvgIcon>
                  <XMarkIcon />
                </SvgIcon>
              </IconButton>
            </form>
          }
        />
        <Divider />
        <CardContent>
          {searchItems.map(
            (item, i) =>
              (item.date && (
                <CashflowItem
                  key={i}
                  item={item}
                  currency={currency}
                  fx_rates={fx_rates}
                  onClick={() => openCashflowForm(item)}
                />
              )) || (
                <TransactionRecord
                  key={i}
                  transaction={item}
                  currency={currency}
                  readOnly={current_user.role_value == "read_only"}
                  fx_rates={fx_rates}
                  onClick={
                    item.status == "placed"
                      ? () => openTransactionForm(item)
                      : undefined
                  }
                />
              ),
          )}
          {searchItems.length == 0 && <I18n no_automation />}
        </CardContent>
      </Card>
    </Stack>
  );
}

export default function (props) {
  const { open, onClose } = props;

  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("search")}
      icon={MagnifyingGlassIcon}
    >
      <SearchDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
