import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import I18n from "../../common/I18n";

const SYFE_LOGIN_URL = "https://www.syfe.com/login";

export default function ({ account, onClick }) {
  return (
    <Grid
      key={account.id}
      container
      spacing={0}
      onClick={onClick}
      sx={Object.assign(
        {
          p: 2,
          alignItems: "start",
          borderRadius: 1,
          "&:not(:last-child)": { borderBottom: "solid 0.5px #eee" },
        },
        onClick ? { cursor: "pointer" } : {},
      )}
    >
      <Grid item xs={8} md={9}>
        <Typography variant="body1">
          {account.id}.&nbsp;&nbsp;<b>{account.name}</b>
        </Typography>
      </Grid>
      <Grid item xs={4} md={3}>
        <Typography variant="subtitle2">
          Created at <I18n l="date.short" v={account.created_at} />
          ,&nbsp;
          {account.users?.length || 0} users
          {account.profile.referred_from && (
            <>
              ,&nbsp; Referred from <b>{account.profile.referred_from}</b>
            </>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2} sx={{ display: "flex", p: 2 }}>
          {account.bank_accounts
            ?.sort(
              (a, b) =>
                a.bank.id - b.bank.id || (a.currency > b.currency ? 1 : -1),
            )
            .map((bank_account) => (
              <Grid
                item
                xs={6}
                md={6}
                key={bank_account.id}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Avatar
                  src={bank_account.bank.image}
                  variant="rounded"
                  sx={{ mr: 1, width: 24, height: 24 }}
                />
                <Typography variant="body2">
                  {bank_account.name}
                  &nbsp;&nbsp;
                  {bank_account.currency}
                  &nbsp;
                  {bank_account.status == "suspended" && " (suspended)"}
                </Typography>
                {bank_account.bank_type == "investment" && (
                  <Button
                    variant="outlined"
                    size="small"
                    target="_blank"
                    href={SYFE_LOGIN_URL + "?account_id=" + account.id}
                    sx={{
                      marginLeft: "auto",
                      padding: "4px 6px",
                      fontSize: "70%",
                    }}
                  >
                    Go Syfe
                  </Button>
                )}
              </Grid>
            ))}
        </Grid>
        <Grid container spacing={2} sx={{ display: "flex", p: 2 }}>
          {account.app_integrations.map((app_integration) => (
            <Grid
              item
              xs={6}
              md={3}
              key={app_integration.id}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                src={app_integration.app.image}
                variant="rounded"
                sx={{ mr: 1, width: 24, height: 24 }}
              />
              <Typography variant="body2">
                {app_integration.app.name}
                {app_integration.status == "disabled" && " (disabled)"}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
